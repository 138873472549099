
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {Field} from "vee-validate";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {useRouter} from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";


interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcVoucherNo: string;
  srcAccountVoucherNo: string;
  srcAccount: number;
  srcDepartment: number;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
}

export default defineComponent({
  name: "noninvoices-list",

  components: {
    Field,
  },


  computed: {
    filteredTableList: function () {
      if (this.arrNoninvoicesList !== undefined) {
        return this.arrNoninvoicesList;
      } else {
        return [];
      }
    },
  },

  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
    }
  },

  methods: {
    ...dateConvert.method,

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },

  setup() {
    const router = useRouter();

    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
      store.dispatch(Actions.ACCOUNT_LIST_ALL, {});

    });

    const arrNoninvoicesList = ref([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const arrCheckedReservations = ref([]);
    const indexCheckedReservations = ref(0);


    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcVoucherNo: "",
      srcAccountVoucherNo: "",
      srcAccount: 0,
      srcDepartment: 0,
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
    });

    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }

    function getProductTitle(prmEach) {

      if ((prmEach as any).ProductTitle!==null) {
        //console.log("getProductTitle ProductTitle: "+(prmEach as any).ProductTitle);
        let objProductTitle = JSON.parse((prmEach as any).ProductTitle);
        if ((objProductTitle as any).HOTEL!==undefined) {
          return (objProductTitle as any).HOTEL[0];
        }
      }

      return "";
    }

    function getProductCounts(prmEach, prmType) {
      if ((prmEach as any).ProductCounts!==null) {
        let objParsed = JSON.parse((prmEach as any).ProductCounts);

        if (prmType==="FLIGHT" && (objParsed as any).FLIGHT!==undefined) {
          return Number((objParsed as any).FLIGHT);
        }
        if (prmType==="HOTEL" && (objParsed as any).HOTEL!==undefined) {
          return Number((objParsed as any).HOTEL);
        }
        if (prmType==="TRANSFER" && (objParsed as any).TRANSFER!==undefined) {
          return Number((objParsed as any).TRANSFER);
        }
      }

      return "";
    }

    const click_Search = () => {
        if (!submitButtonRef.value) {
          return;
        }
        arrNoninvoicesList.value = [];
        const payload1 = {
          StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
          StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
          FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
          FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
          ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
          ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
          VoucherNo: srcItem.value.srcVoucherNo,
          AccountVoucherNo: srcItem.value.srcAccountVoucherNo,
          Department: srcItem.value.srcDepartment,
          AccountID: srcItem.value.srcAccount,
          /*ProcessDateStart: "2024-03-02",
          ProcessDateFinish: "2024-03-02",*/
        };


        store.dispatch(Actions.RESERVATIONS_NONINVOICES_LIST, payload1).then((prmData) => {
          arrNoninvoicesList.value = prmData.data;
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          //console.log(submitButtonRef.value);
        }).catch((prmData) => {
          libTools.method.swalError("Tamam", "Hata Oluştu");
        });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      //console.log(submitButtonRef.value);
    };


    function clickCheckbox(prmResID) {
      //isAllSame();
      if ( isAllSame() === false){
        console.log("Eşit değil");
        const checkboxid = document.getElementById("inputNonInvoiceReservation_"+prmResID) as HTMLInputElement;
        checkboxid.checked = false;
      }
      getCheckedIDs();
    }


    function routerClick() {
      //router.push({ name: 'invocie', params: { id: "[" + arrCheckedReservations.value + "]" }});
      if(arrCheckedReservations.value !== null){
        router.push({ name: 'noninvocie', params: { id: "[" + arrCheckedReservations.value + "]" }});
      }else{
        libTools.method.swalError("Tamam", "En az bir ürün seçiniz." );
      }

    }


    function getCheckedIDs(){
      const vvClassName = "inputNonInvoiceReservation";
      arrCheckedReservations.value=[];
      indexCheckedReservations.value=0;
      const arrElements = document.getElementsByClassName(vvClassName);
      for (let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;

        //console.log(eachElemnt.getAttribute("reservationID") + " " + eachElemnt.checked + " " + eachElemnt.getAttribute("accountID"));
        if (eachElemnt.checked) {
          arrCheckedReservations.value.push(eachElemnt.getAttribute("reservationID"));

        }
      }
      //console.log("ArrCheckedReservations Value " + JSON.stringify(arrCheckedReservations.value));

      return arrCheckedReservations.value;
    }


    function isAllSame(){
        const vvClassName = "inputNonInvoiceReservation";
        arrCheckedReservations.value=[];
        const objPrevius = {};
        const arrElements = document.getElementsByClassName(vvClassName);
          for (let i=0; i<arrElements.length; i++){
             let eachElemnt = arrElements[i] as HTMLInputElement;


             if (eachElemnt.checked) {
               //console.log( eachElemnt.checked + " Each AccountID " + eachElemnt.getAttribute("accountID") + " Each reservationID " + eachElemnt.getAttribute("reservationID") + " " +  (objPrevius as any).reservationID);
               if((objPrevius as any).accountID !== undefined){
                 if((objPrevius as any).accountID !== eachElemnt.getAttribute("accountID")){

                   //console.log( " Eşit değil AccountIDs " + eachElemnt.getAttribute("accountID") + " Each reservationID " + eachElemnt.getAttribute("reservationID") + " Öceki Account ID " + (objPrevius as any).accountID + " Önceki REs ID " + (objPrevius as any).reservationID);
                   return false;
                 }
               }
               (objPrevius as any).accountID = eachElemnt.getAttribute("accountID");
               (objPrevius as any).reservationID = eachElemnt.getAttribute("reservationID");
             }
          }

        return true;
    }




    onMounted(() => {
      setCurrentPageBreadcrumbs("Faturalandırılmamış Ürünler", ["Faturalar"]);
    });

    return {
      dateConvert,
      libTools,
      submitButtonRef,
      srcItem,
      myDepartments,
      myAccountList,
      click_Search,
      arrNoninvoicesList,
      getProductTitle,
      getProductCounts,
      clickCheckbox,
      getCheckedIDs,
      isAllSame,
      routerClick,
      arrCheckedReservations,


    };
  },
});
